import React from 'react'
import './Style.css';
import './Staff.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import AboutBanner from './img/banners/about.webp';
import AboutBannerMob from './img/banners/about-mob.webp';

import Year from './img/49-Years-Logo.png';
import NewTechnology from './img/icon-technology.webp';
import StrongPool from './img/icon-strong.webp';
import NewShapes from './img/icon-shapes.webp';
import ProductConformance from './img/icon-conformance.webp';
import Year49 from './img/49.webp';
import Export from './img/icon-export.webp';
import Materials from './img/icon-materials.webp';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
function About() {
    return (
        <>

            <HelmetProvider>

                <Helmet>
                    <html lang="en" />
                    <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada</title>
                    <link rel="canonical" href="https://www.aquatechnicspools.ca/About/" />
                    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
                    <meta charSet="utf-8" />
                    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
                    <meta name="description" content="Choose from 17 stylish designs using the latest technology and enjoy a lifetime of memories, guaranteed. Aqua Technics Pools have been manufacturing award-winning fibreglass swimming pools for over 47 years." />
                    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
                    <meta property="og:title" content="About og title" />
                    <meta property="og:description" content="og-description" />
                </Helmet>

            </HelmetProvider>

            <Header />

            <div className='inner_container'>
                <div className='header-banner'><img src={AboutBanner} alt="Aqua Technics Canada" /></div>
                <div className='header-banner-mob'><img src={AboutBannerMob} alt="Aqua Technics Canada" /></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>Aqua Technics</h1>
                        <h3>Fibreglass Swimming Pools </h3>
                        <p><img className="year-logo" src={Year} alt="Aqua Technics Canada" />Along with our state-of-the-art manufacturing facility, we can proudly boast to having our own in-house raw materials production division. This allows us to freshly batch our raw materials on a daily basis, which is extremely important in order to produce a superior quality swimming pool.</p>
                        <p>Our pools are manufactured in accordance with a Quality Management System as per the requirements of International Standard ISO 9001. This means we are measured and tested by an authorized, independent external auditor each year.</p>
                    </div>
                    <div className='staff'></div>

                    <div className='inner_body'><h2>What Sets Us Apart from the Rest</h2>
                        <div className='sets-icon'><img src={NewTechnology} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>New Technology</em><br />Introducing ground-breaking Graphene Nano-Tech<sup>®</sup>, creating 30% stronger pools built like no other. Graphene nano technology is infused in the composite layers of the pool, creating a totally new construction process. Every pool also has Pool ColourGuard<sup>®</sup> surface protection, with superior fade resistance.
                            </p></div>
                        <div className='sets-icon'><img src={StrongPool} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>Strong Pools</em><br />Wide, 8” edges beam, which like the steps and seating areas are braced for maximum support and added strength. The strength is engineered into the pool.
                            </p></div>
                        <div className='sets-icon'><img src={NewShapes} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>New Shapes</em><br />Modern designs that are easy to install. Precision moulds are built to exacting tolerances, so the edge beams are true and the shapes geometrically perfect. Our designs have received many industry accolades over the years we have been in the swimming pool industry, including 17 International Awards of Excellence – proof that the quality of our pools is recognised the world over.
                            </p></div>
                        <div className='sets-icon'><img src={ProductConformance} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>Product Conformance</em><br />Aqua Technics fibreglass swimming pools are manufactured in accordance with a Quality Management System ISO 9001, one of only five companies globally.
                            </p></div>
                        <div className='sets-icon'><img src={Year49} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>49 Years Manufacturing Experience</em><br />We have been building award-winning fibreglass pools since 1976 and have manufactured and installed over 70,000 while remaining a family owned and operated company. Our world-class production facility has been purpose built for the manufacture of fibreglass swimming pools.
                            </p></div>
                        <div className='sets-icon'><img src={Export} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>Shipped Around the World</em><br />The quality and strength of our pools is the reason they have been shipped to 17 countries world-wide, many of which we have been active in for over 15 years. Sharing the love!
                            </p></div>
                        <div className='sets-icon'><img src={Materials} alt="Aqua Technics - Canada" />
                            <p><em className='abt'>Superior Materials</em><br />Our on-site gelcoat manufacturing facility means we can batch and manufacture in the same timeframe – this is massively important for a quality product. Only specialty materials are used - no general purpose products.
                            </p></div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}

export default About
