import { useEffect } from 'react';

export const useGooglePlaces = (setValue) => {
  useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      console.error('Google Maps API key is missing in environment variables');
      return;
    }

    let scriptLoadAttempted = false;

    const initializeAutocomplete = () => {
      const addressInput = document.getElementById('address-input');
      if (!addressInput) return;

      try {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInput, {
          componentRestrictions: { country: 'ca' },
          fields: ['address_components', 'formatted_address', 'place_id'], // Added place_id to fields
          types: ['address']
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (!place?.address_components) return;

          // Store both the formatted address and place_id
          setValue('address', place.formatted_address);
          setValue('placeId', place.place_id); // Store the place_id

          const postalComponent = place.address_components.find(
            component => component.types.includes('postal_code')
          );
          if (postalComponent) {
            setValue('zipCode', postalComponent.long_name);
          }
        });
      } catch (error) {
        console.error('Error initializing Google Places Autocomplete:', error);
      }
    };

    const loadGoogleMapsScript = () => {
      if (scriptLoadAttempted) return;
      scriptLoadAttempted = true;

      if (window.google?.maps) {
        initializeAutocomplete();
        return;
      }

      const script = document.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onerror = () => {
        console.error('Failed to load Google Maps script');
      };
      script.onload = initializeAutocomplete;
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();

    return () => {
      if (scriptLoadAttempted) {
        const script = document.getElementById('google-maps-script');
        if (script) {
          script.remove();
        }
      }
    };
  }, [setValue]);
};