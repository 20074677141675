import React from 'react';
import './Style.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import QuoteBanner from './img/banners/geta-a-quote.webp';
import QuoteBannerMob from './img/banners/get-a-quote-mob.webp';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import QuoteForm from '../components/forms/QuoteForm';

function Get_a_Quote() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Get a Quote</title>
          <link rel="canonical" href="https://www.aquatechnicspools.ca/Get-a-quote/" />
          <meta charSet="utf-8" />
          <meta name="title" content="Get Your Quote - Aqua Technics Canada - Fibreglass Swimming Pools" />
          <meta 
            name="description" 
            content="Looking for a quote on a swimming pool? Browse our wide range of fibreglass swimming pools today and instantly request a free quote, it's that simple!. Built like no other. Best Fibreglass Pool Dealers in Canada. World-class fibreglass swimming pools built like no other. Aqua Technics Pools manufacture the most advanced and highest quality fibreglass composite pools in the world. Our technologically advanced swimming pool range is designed for unparalleled strength with superior colour retention, ensuring long-lasting colour vibrancy." 
          />
          <meta 
            name="keywords" 
            content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, fibreglass pool colours, swimming pool colour, pool colour options, swimming pool gallery, pool design ideas, premium pool construction, durable swimming pools, pool dealer opportunities, become a pool dealer, contact aqua technics, pool inquiries canada, pool installation quote, swimming pool pricing, swimming pool quote" 
          />
          <meta property="og:title" content="Get a Quote - Aqua Technics Canada" />
          <meta property="og:description" content="Request a free quote for your dream fibreglass swimming pool. Explore our wide range of high-quality pools and get started on your backyard transformation today." />
        </Helmet>
      </HelmetProvider>
      
      <Header />
      
      <div className="inner_container">
        <div className="header-banner">
          <img src={QuoteBanner} alt="Aqua Technics Canada Swimming Pools" />
        </div>
        <div className="header-banner-mob">
          <img src={QuoteBannerMob} alt="Aqua Technics Canada Swimming Pools" />
        </div>
        
        <div className="inner-body-container">
          <div className="inner_body">
            <h1>Get a Quote</h1>
            <div className="quote-content">
              <p className="mb-6 text-gray-700">
                Ready to transform your backyard into a paradise? Fill out the form below to get a customized quote for your dream pool. Our team will review your requirements and get back to you shortly with detailed pricing and options.
              </p>
              <div className="form-container">
                <QuoteForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
}

export default Get_a_Quote;