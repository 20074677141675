// useFormSubmission.js
import { useState } from 'react';

const validateRecaptcha = (token) => {
  return Boolean(token && token.length > 0);
};

const validateFormData = (data) => {
  const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'address', 'poolModels'];
  
  for (const field of requiredFields) {
    if (field === 'poolModels') {
      if (!data[field]?.length) {
        throw new Error('Please select at least one pool model');
      }
      continue;
    }
    
    if (!data[field]?.trim()) {
      throw new Error(`${field.charAt(0).toUpperCase() + field.slice(1)} is required`);
    }
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(data.email)) {
    throw new Error('Please enter a valid email address');
  }

  const phoneRegex = /^[\d\s()+.-]{10,}$/;
  if (!phoneRegex.test(data.phone)) {
    throw new Error('Please enter a valid phone number');
  }
};

export const useFormSubmission = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [error, setError] = useState(null);

  const submitForm = async (data) => {
    setIsSubmitting(true);
    setError(null);

    try {
      if (!validateRecaptcha(data.recaptchaToken)) {
        throw new Error('Please complete the reCAPTCHA verification');
      }

      validateFormData(data);

      const webhookUrl = window.ENV.ZAPIER_WEBHOOK_URL;
      if (!webhookUrl) {
        throw new Error('Form submission is not properly configured. Please contact support.');
      }

      const lastSubmission = localStorage.getItem('lastFormSubmission');
      const currentTime = new Date().getTime();
      if (lastSubmission && (currentTime - parseInt(lastSubmission)) < 30000) {
        throw new Error('Please wait at least 30 seconds between submissions');
      }

      const submissionData = {
        ...data,
        submitted_at: new Date().toISOString(),
        address_details: {
          formatted_address: data.address || '',
          place_id: data.placeId || '',
          postal_code: data.zipCode || ''
        },
        security_metadata: {
          submission_timestamp: new Date().toISOString(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          submission_id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          form_version: '1.0'
        }
      };

      const sanitizedData = { ...submissionData };
      delete sanitizedData.recaptchaToken;

      await fetch(webhookUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData)
      });

      localStorage.setItem('lastFormSubmission', currentTime.toString());
      setShowThankYou(true);
      localStorage.removeItem('formData');
    } catch (error) {
      console.error('Form submission error:', error);
      setError(error.message || 'There was an error submitting the form. Please try again.');
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, showThankYou, error, submitForm };
};