// src/components/forms/QuoteForm/PoolSelection.js
import React from 'react';

const POOL_CATEGORIES = {
  small: [
    ["Infinity", "11'2\" diameter"],
    ["Sovereign", "10'2\" X 17'9\""],
    ["Istana", "12'1\" X 21'7\""]
  ],
  medium: [
    ["Bellino", "13'1\" X 22'8\""],
    ["Oasis", "13'5\" x 22'9\""],
    ["Terazza", "12'1\" x 25'3\""],
    ["Castello", "11'1\" X 25'11\""],
    ["Bedarra", "13'9\" x 25'11\""],
    ["Saint-Remy", "13'1\" x 26'3\""],
    ["Cambridge", "13'1\" x 26'3\""]
  ],
  large: [
    ["Haven", "14'5\" x 26'4\""],
    ["Elysian", "12'2\" X 28'7\""],
    ["Hayman", "13'9\" X 29'2\""],
    ["Amalfi", "13'1\" x 30'10\""],
    ["Westminster", "14'5\" X 30'10\""],
    ["Saint-Louis", "14'5\" X 32'6\""],
    ["Kensington", "14'5\" X 37'5\""],
    ["Hayman-Lounger", "15'11\" x 39'"],
    ["Saint-Grande", "15'11\" x 39'"]
  ],
  other: [
    ["Nova", "7'2\" X 8'6\""],
    ["Aqua Ledge", "7'8\" x 14'4\""]
  ]
};

export const PoolSelection = ({ register }) => {
  return (
    <div className="qt-range-container">
      {Object.entries(POOL_CATEGORIES).map(([category, pools]) => (
        <div key={category} className="qt-range-col">
          <span className="form-pool-title">{category.charAt(0).toUpperCase() + category.slice(1)} Pools</span>
          <div>
            {pools.map(([name, size]) => (
              <div key={name}>
                <input
                  type="checkbox"
                  {...register('poolModels')}
                  value={name}
                  className="chkbx"
                />
                <span>{name} - {size}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};