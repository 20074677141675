import React from 'react';

const Alert = React.forwardRef(({ className, variant = "default", children, ...props }, ref) => {
  const baseStyles = "relative w-full rounded-lg border p-4";
  const variants = {
    default: "bg-white text-gray-900 border-gray-200",
    destructive: "border-red-500/50 text-red-600 dark:border-red-500 [&>svg]:text-red-600",
    success: "border-green-500/50 text-green-600 dark:border-green-500 [&>svg]:text-green-600"
  };
  return (
    <div
      ref={ref}
      role="alert"
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
});

Alert.displayName = "Alert";

const AlertTitle = React.forwardRef(({ className, children, ...props }, ref) => (
  <h5
    ref={ref}
    className={`mb-1 font-medium leading-none tracking-tight ${className}`}
    {...props}
  >
    {children || 'Alert'}
  </h5>
));

AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-sm [&_p]:leading-relaxed ${className}`}
    {...props}
  />
));

AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };