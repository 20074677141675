import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card } from '../../ui/Card';
import { Alert, AlertTitle, AlertDescription } from '../../ui/Alert';
import { useGooglePlaces } from './hooks/useGooglePlaces';
import { useFormSubmission } from './hooks/useFormSubmission';
import { PoolSelection } from './PoolSelection';
import './QuoteForm.css';

const QuoteForm = () => {
  const [isRecaptchaLoaded, setRecaptchaLoaded] = useState(false);

  const validationRules = {
    firstName: { 
      required: 'First name is required',
      pattern: {
        value: /^[A-Za-z\s'-]{2,}$/,
        message: 'Please enter a valid first name'
      }
    },
    lastName: { 
      required: 'Last name is required',
      pattern: {
        value: /^[A-Za-z\s'-]{2,}$/,
        message: 'Please enter a valid last name'
      }
    },
    email: { 
      required: 'Email is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Please enter a valid email address'
      }
    },
    phone: {
      required: 'Phone number is required',
      pattern: {
        value: /^\+?[\d\s()-]{10,}$/,
        message: 'Please enter a valid phone number'
      }
    },
    address: {
      required: 'Address is required'
    },
    zipCode: {
      pattern: {
        value: /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i,
        message: 'Please enter a valid Canadian postal code'
      }
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      poolModels: [],
      contactMethod: 'email',
      placeId: '',
      address: '',
      zipCode: ''
    },
    mode: 'onBlur'
  });

  useGooglePlaces(setValue);
  const { isSubmitting, showThankYou, error, submitForm } = useFormSubmission();
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (!window.ENV.RECAPTCHA_SITE_KEY) {
      console.error('reCAPTCHA site key is missing in configuration');
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => setRecaptchaLoaded(true);
    script.onerror = () => console.error('Failed to load reCAPTCHA');
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      setRecaptchaLoaded(false);
    };
  }, []);

  const onSubmit = async (data) => {
    try {
      if (!isRecaptchaLoaded) {
        throw new Error('Please wait for reCAPTCHA to load');
      }

      const recaptchaValue = window.grecaptcha?.getResponse();
      if (!recaptchaValue) {
        throw new Error('Please complete the reCAPTCHA verification');
      }

      await submitForm({ ...data, recaptchaToken: recaptchaValue });
    } catch (error) {
      console.error('Form submission error:', error);
      window.grecaptcha?.reset();
    }
  };

  if (showThankYou) {
    return (
      <Card className="p-6">
        <div id="thankYouMessage" className="text-center">
          <h3 className="text-xl font-bold mb-4">Thank You!</h3>
          <p className="text-gray-700">Your quote request has been submitted successfully. We'll be in touch soon!</p>
        </div>
      </Card>
    );
  }

  return (
    <div className="qt-form-container">
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="quote-form-sub-title-1">Which pool model(s) are you interested in?</div>
        <PoolSelection register={register} />
        {errors.poolModels && (
          <p className="error-message">Please select at least one pool model</p>
        )}

        <div className="form-row">
          <div className="quote-form-sub-title-1">Contact Details: (<em className="required-indicator">*</em>)</div>
        </div>

        <div className="form-row">
          <div className="row-left">
            <input
              type="text"
              {...register('firstName', validationRules.firstName)}
              placeholder="First Name"
              className="qt-input"
            />
            {errors.firstName && (
              <p className="error-message">{errors.firstName.message}</p>
            )}
          </div>
          <div className="row-right">
            <input
              type="text"
              {...register('lastName', validationRules.lastName)}
              placeholder="Last Name"
              className="qt-input"
            />
            {errors.lastName && (
              <p className="error-message">{errors.lastName.message}</p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="row-left">
            <input
              type="email"
              {...register('email', validationRules.email)}
              placeholder="Email"
              className="qt-input"
            />
            {errors.email && (
              <p className="error-message">{errors.email.message}</p>
            )}
          </div>
          <div className="row-right">
            <input
              type="tel"
              {...register('phone', validationRules.phone)}
              placeholder="Daytime Tel"
              className="qt-input"
            />
            {errors.phone && (
              <p className="error-message">{errors.phone.message}</p>
            )}
          </div>
        </div>

        <div className="quote-form-sub-title-1">
          How would you prefer us to contact you? (<em className="required-indicator">*</em>)
        </div>
        <div className="form-row">
          <div>
            <label>
              <input
                type="radio"
                {...register('contactMethod')}
                value="email"
                defaultChecked
              /> Email
            </label>
            <label style={{ marginLeft: '20px' }}>
              <input
                type="radio"
                {...register('contactMethod')}
                value="phone"
              /> Phone
            </label>
          </div>
        </div>

        <div className="quote-form-sub-title-1">
          Where will your pool be installed? (<em className="required-indicator">*</em>)
        </div>
        <div className="form-row">
          <input
            id="address-input"
            type="text"
            {...register('address', validationRules.address)}
            placeholder="Enter a location"
            className="qt-input"
          />
          <input
            type="hidden"
            {...register('placeId')}
          />
          {errors.address && (
            <p className="error-message">{errors.address.message}</p>
          )}
        </div>

        <div className="form-row">
          <div className="col-01">
            <input
              type="text"
              {...register('zipCode', validationRules.zipCode)}
              placeholder="Postal Code"
              className="qt-input"
            />
            {errors.zipCode && (
              <p className="error-message">{errors.zipCode.message}</p>
            )}
          </div>
        </div>

        <div className="quote-form-sub-title-1">Anything to add?</div>
        <div className="form-row">
          <textarea
            {...register('additionalInfo')}
            placeholder="More Information"
            rows={4}
            className="qt-textarea"
          />
        </div>

        <div className="form-row">
          <div 
            ref={recaptchaRef}
            className="g-recaptcha" 
            data-sitekey={window.ENV.RECAPTCHA_SITE_KEY}
          ></div>
          {!isRecaptchaLoaded && (
            <p className="text-sm text-gray-500">Loading reCAPTCHA...</p>
          )}
        </div>

        <div className="form-row">
          <div className="row-right">
            <button
              type="submit"
              disabled={isSubmitting || !isRecaptchaLoaded}
              className="step_2"
            >
              {isSubmitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuoteForm;